import * as React from "react";
import styled from "styled-components";
import PageContainer from "./pageContainer.js";
import { when } from "../styles/theme.js";
import { useLocalization } from "gatsby-theme-i18n";

const Container = styled.div`
  position: relative;
`;

const SideBarContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  ${when("lg")} {
    display: block;
  }
`;

const Wrapper = styled(PageContainer)`
  height: 100%;
`;

const NavContainer = styled.aside`
  position: sticky;
  top: 0;
  padding-top: 5rem;
  pointer-events: auto;

  ${when("lg")} {
    width: ${(props) => `${props.theme.sideBarWidth.lg}rem;`};
  }

  ${when("xl")} {
    width: ${(props) => `${props.theme.sideBarWidth.xl}rem;`};
  }

  ${when("xxl")} {
    width: ${(props) => `${props.theme.sideBarWidth.xxl}rem;`};
  }
`;

const Link = styled.div`
  font-weight: ${(props) => (props.isEn ? "600" : "500")};
  font-size: ${(props) =>
    props.isEn
      ? props.isActive
        ? "2.8rem"
        : "1.8rem"
      : props.isActive
      ? "4rem"
      : "2rem"};
  color: ${(props) =>
    props.isActive ? props.theme.colors.red : props.theme.colors.gray};
  line-height: 1;
  padding: 1.5rem 0;
  cursor: pointer;
`;

const SideBar = ({ children, sectionTitles, currentSection }) => {
  const handleItemClick = (selection) => {
    document.getElementById(`section-${selection}`).scrollIntoView({
      behavior: "smooth",
    });
  };

  const { locale } = useLocalization();

  return (
    <Container>
      <SideBarContainer>
        <Wrapper>
          <NavContainer>
            {sectionTitles.map((sectionTitle, index) => {
              return (
                <Link
                  isActive={currentSection === index}
                  key={index}
                  onClick={() => handleItemClick(index)}
                  isEn={locale === "en"}
                >
                  {sectionTitle.title}
                </Link>
              );
            })}
          </NavContainer>
        </Wrapper>
      </SideBarContainer>
      {children}
    </Container>
  );
};

export default SideBar;
